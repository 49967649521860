<template>
  <div>
    <div class="sticky top-0 z-10">
      <nav class="px-4 py-2.5 bg-gray-800 z-20">
        <div class="relative flex flex-wrap items-center justify-between mx-auto">
          <span class="self-center text-xl font-semibold text-white whitespace-nowrap">
            <span v-if="header">{{ header }}</span>

            <NuxtLink
              href="/forms"
              v-else
            >
              <span>{{ title }}</span>
            </NuxtLink>
          </span>
          <button
            data-collapse-toggle="mobile-menu"
            type="button"
            class="inline-flex items-center p-2 ml-3 text-sm text-gray-400 rounded-lg md:hidden focus:outline-none focus:ring-2 hover:bg-gray-700 focus:ring-gray-600"
            aria-controls="mobile-menu"
            aria-expanded="false"
            @click="() => { openMenu = !openMenu; profileDropdown = false; }"
          >
            <span class="sr-only">Open main menu</span>
            <MenuClosed
              class="w-6 h-6"
              v-show="!openMenu"
            />
            <XMark
              class="w-6 h-6"
              v-show="openMenu"
            />
          </button>

          <div
            class="w-full overflow-x-hidden overflow-y-hidden transition-all duration-500 md:ml-3 md:flex-1 md:max-h-48"
            :class="{
              'max-h-0': !openMenu,
              'max-h-48': openMenu,
            }"
          >
            <ul class="flex flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:justify-end">
              <li>
                <NuxtLink
                  href="/forms"
                  @click="() => { openMenu = false; profileDropdown = false; }"
                  :class="isActive('forms')"
                  aria-current="page"
                  class="outline-none focus:underline focus:bg-gray-50 focus:bg-opacity-10 underline-offset-2"
                >All Forms</NuxtLink>
              </li>

              <li>
                <NuxtLink
                  href="/pending?state=submitted"
                  @click="() => { openMenu = false; profileDropdown = false; }"
                  :class="(route.name === 'pending' && (!route.query.state || route.query.state === 'submitted')) ? 'active-nav-item' : 'inactive-nav-item'"
                  class="outline-none focus:underline focus:bg-gray-50 focus:bg-opacity-10 underline-offset-2"
                >All Pending Submissions</NuxtLink>
              </li>

              <li>
                <NuxtLink
                  href="/pending?state=draft"
                  @click="() => { openMenu = false; profileDropdown = false; }"
                  :class="(route.name === 'pending' && route.query.state === 'draft') ? 'active-nav-item' : 'inactive-nav-item'"
                  class="outline-none focus:underline focus:bg-gray-50 focus:bg-opacity-10 underline-offset-2"
                >All Draft Submissions</NuxtLink>
              </li>

              <li
                v-if="user"
                class="relative select-none text-slate-400 inactive-nav-item"
                @click="profileDropdown = !profileDropdown"
              >
                {{ (user.data as User).email }}
              </li>
            </ul>
          </div>

          <div
            class="absolute z-10 p-3 mt-3 text-white transition-all bg-black rounded-md w-fit top-full min-w-max"
            :class="{
              'opacity-100 -right-3 visible': profileDropdown,
              'opacity-0 right-3 invisible': !profileDropdown,
            }"
            v-if="user"
          >
            <p class="text-sm">Role: <span class="uppercase">{{ (user.data as User).role }}</span></p>
            <p class="text-sm">
              <span>Branch: {{ (user.data as User).branch?.name ?? 'N/A' }}</span>
              <span> ({{ (user.data as User).branch_id ?? '0' }})</span>
            </p>

            <p class="mt-2 text-sm text-red-500">
              <button
                class="hover:underline underline-offset-4"
                @click="logout()"
              >Logout</button>
            </p>
          </div>
        </div>
      </nav>

      <!-- Progress Bar -->
      <div
        class="w-full h-1.5 -mt-0.5 bg-gray-300"
        v-if="progress !== null"
      >
        <div
          class="h-full bg-primary"
          :style="{ width: `${progress}%`, transition: 'width 0.6s 0.2s ease-in-out' }"
        ></div>
      </div>
    </div>

    <div class="p-4 bg-white shadow md:p-6">
      <img
        src="/img/support-logo.png?v=1"
        :alt="title"
        class="w-auto mx-auto mb-4 select-none h-14"
        draggable="false"
      />
      <slot />
    </div>

    <!-- Scroll to Top -->
    <Transition name="fade">
      <button
        type="button"
        class="fixed z-50 inline-block p-3 text-xs font-medium leading-tight text-white uppercase transition-all duration-500 ease-in-out bg-blue-400 rounded-full shadow-md opacity-70 md:hover:opacity-100 hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg bottom-16 right-2 hover:opacity-10"
        @click="scrollToTop"
        v-if="y > 200"
      >
        <ArrowUp class="w-5 h-5" />
      </button>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { useSessionStorage, useWindowScroll } from '@vueuse/core';
import ArrowUp from '~~/components/icons/ArrowUp.vue';
import MenuClosed from '~~/components/icons/MenuClosed.vue';
import XMark from '~~/components/icons/XMark.vue';
import { User } from '~~/composables/useLogin';

const title = useRuntimeConfig().public.title;

const openMenu = ref(false);

const header = ref<string | null>(null);
const progress = ref<number | null>(null);
const profileDropdown = ref(false);

const route = useRoute();

const { user, logout } = useLogin();

const isActive = (name: string) => {
  return route.name === name ? 'active-nav-item' : 'inactive-nav-item';
}

const { y } = useWindowScroll();

const scrollToTop = () => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth'
  })
}

provide('header', header);
provide('progress', progress);

onMounted(async () => {
  const fetchedUser = useSessionStorage('fetchedUser', false);

  if (!fetchedUser.value) {
    const { fetchUser } = useLogin();
    await fetchUser();

    fetchedUser.value = true;
  }
})
</script>
