<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
    fill="currentColor"
    d="M12 20a.968.968 0 0 1-.713-.288A.968.968 0 0 1 11 19V7.825L6.125 12.7c-.2.2-.438.3-.713.3a.973.973 0 0 1-.712-.3.96.96 0 0 1-.3-.7c0-.267.1-.5.3-.7l6.6-6.6c.1-.1.208-.17.325-.213a1.218 1.218 0 0 1 .762 0 .68.68 0 0 1 .313.213l6.6 6.6c.2.2.3.433.3.7 0 .267-.1.5-.3.7-.2.2-.438.3-.713.3a.973.973 0 0 1-.712-.3L13 7.825V19c0 .283-.096.52-.288.712A.968.968 0 0 1 12 20Z"
  />
</svg></template>
